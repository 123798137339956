import React from "react";
import { Typography, makeStyles, Container } from "@material-ui/core";
import Navbar from "../components/nav/Nav";
import Footer from "../components/nav/Footer";
import Link from "../components/Link";
import { Button, Box } from "@material-ui/core";
import pdfagb from "../media/pdf/AGB_M3E_GmbH.pdf";
import pdf from "../media/pdf/Widerrufsformular.pdf";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    marginTop: "7rem",
    marginBottom: "2rem",
  },
});

const Agb = () => {
  const classes = useStyles();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  return (
    <>
      <Navbar />
      <div className={classes.root}>
        <Container maxWidth="md">
          <Typography variant="h2" align="center" gutterBottom>
            Allgemeine Geschäftsbedingungen
          </Typography>
          <Typography variant="h3" align="center" gutterBottom>
            M3E GmbH
          </Typography>
          <Typography variant="h3" align="center" gutterBottom>
            Stand: 22.05.2023
          </Typography>
          <Typography variant="h5" gutterBottom>
            Präambel
          </Typography>
          <Typography variant="body1" gutterBottom>
            Die M3E GmbH, Torstraße 23, 10119 Berlin (nachfolgend: <b>„M3E“</b>)
            bietet ihren Kunden (nachfolgend: <b>„Kunde“</b>)1 verschiedene
            Dienstleistungen im Zusammenhang mit dem Kauf und dem Betrieb von
            Elektrofahrzeugen an. Diese Allgemeinen Geschäftsbedingungen
            (nachfolgend: <b>„AGB“</b>) gelten für die Erbringung der
            nachfolgend beschriebenen Dienstleistungen „THG Service“ und
            „Antragsservice“ durch die M3E:
          </Typography>
          <Typography variant="h5" gutterBottom>
            <b> 1. THG Service</b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Die M3E sammelt die durch den Einsatz von Elektrofahrzeugen erzeugte
            Erfüllung der Verpflichtung aus §§ 37a ff.
            Bundesimmissionsschutzgesetz (BImSchG) zur Verringerung der
            Treibhausgasemissionen (nachfolgend: „THG-Quote“) und vermarktet die
            THG-Quote gebündelt an Quotenverpflichtete. Maßgeblich ist die
            Verordnung zur Festlegung weiterer Bestimmungen zur
            Treibhausgasminderung bei Kraftstoffen („38. BImSchV“) in der ab dem
            01.01.2022 geltenden Fassung.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Den THG Service können nur Kunden in Anspruch nehmen, die Halter
            eines oder mehrerer reiner Batterieelektrofahrzeuge sind2. Für die
            Inanspruchnahme des THG-Service schließt der Kunde auf Grundlage
            dieser AGB einen Vertrag mit M3E, durch den er die M3E als Dritten
            i.S.v. § 7 Abs. 5 S. 1 38. BImSchV bestimmt. Der Kunde kann das
            Angebot zum Abschluss eines Vertrages entweder direkt gegenüber der
            M3E oder unter Einschaltung eines von der M3E bestimmten Dritten
            (nachfolgend: „Vermittler“) abgeben. Sofern ein Vermittler beteiligt
            ist, übermittelt dieser das Angebot des Kunden als Bote des Kunden
            an die M3E. Auf Grundlage des Vertrages tritt der Kunde die
            THG-Quote, die mit seinen Batterieelektrofahrzeugen erzeugt wird, an
            M3E ab. Im Gegenzug zahlt die M3E nach den nachfolgend geregelten
            Bedingungen eine Vergütung an den Kunden aus.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Darüber hinaus erbringt M3E den THG Service auch als Dienstleistung
            (nachfolgend: „White-Label-Lösung“) für Partner (nachfolgend:
            „Kooperationspartner“). In diesem Fall schließen die Kunden und M3E
            auf Grundlage dieser AGB – unter Vermittlung des
            Kooperationspartners – einen Vertrag, durch den M3E als Dritter
            i.S.v. § 7 Abs. 5 S. 1 38. BImSchV bestimmt wird. Zusätzlich finden
            die Sonderregeln in § 10 Anwendung.
          </Typography>
          <Typography variant="body2" gutterBottom>
            <i>
              1 M3E und Kunde im Folgenden einzeln auch „Partei“ oder zusammen
              „Parteien“
            </i>
          </Typography>
          <Typography variant="body2" gutterBottom>
            <i>
              2 M3E bedient nicht nur Halter einzelner Elektrofahrzeuge
              („E-Mobilist“) sondern auch Halter von Elektroflotten
              („Flottenkunde“).
            </i>
          </Typography>
          <Typography variant="body2" gutterBottom>
            <i>
              3 Ob und inwieweit Vergütung gezahlt wird, richtet sich nach dem
              vertraglichen Verhältnis zwischen Kunde und Kooperationspartner.
            </i>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Allerdings erhalten die Kunden bei der White-Label-Lösung nicht von
            der M3E, sondern ggf.3 vom Kooperationspartner eine Vergütung
            ausgezahlt. Das Verhältnis zwischen M3E und dem Kooperationspartner
            ist in diesen AGB nicht geregelt, sondern Gegenstand eines
            getrennten Kooperationsvertrages.
          </Typography>

          <Typography variant="h5" gutterBottom>
            <b> 2. Antragsservice</b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Es existieren derzeit verschiedene Förderungen für die Anschaffung
            von Elektrofahrzeugen. Die M3E übernimmt für bestimmte Förderungen
            die Antragstellung für den Kunden (nachfolgend: „Antragsservice“).
            Die Förderungen, für die der Antragsservice genutzt werden kann,
            sind auf der Website der M3E zusammengestellt und können jederzeit
            bei der M3E angefragt werden.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Um den Antragsservice nutzen zu können, beauftragt der Kunde die M3E
            mit der Stellung des Förderantrages in seinem Namen und stellt der
            M3E die erforderlichen Informationen und Dokumente zur Verfügung.
            Die M3E beantragt für den Kunden die Förderung beim jeweiligen
            Fördergeber und erhält nach den nachfolgend definierten Bedingungen
            im Gegenzug eine Vergütung vom Kunden. Die Fördersumme wird vom
            Fördergeber direkt an den Kunden ausbezahlt.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Sowohl den THG-Service als auch den Antragsservice erbringt die M3E
            auf Grundlage der AGB.
          </Typography>
          <Typography color="primary" variant="h6" gutterBottom>
            <b> A. Regelungen für die Erbringung des THG-Service</b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Die nachfolgenden Bestimmungen unter Teil A. gelten ausschließlich
            für den THG-Service und betreffen den Vertragsschluss zwischen den
            Parteien, die Anmeldung von Elektrofahrzeugen für den THG-Service,
            die Abtretung der THG-Quote an und deren Vermarktung durch M3E sowie
            den Vergütungsanspruch des Kunden.
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b>
              1. Vertragsschluss; Bestimmung als Dritten i.S.v. § 7 Abs. 5 S. 1
              38. BImSchV
            </b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            1.1 Der Vertrag zwischen M3E und dem Kunden wird entweder
            schriftlich (a) oder elektronisch (b) abgeschlossen. In beiden
            Fällen werden diese AGB Vertragsgegenstand.
          </Typography>
          <Typography variant="body1" gutterBottom>
            a) Für den schriftlichen Vertragsschluss wird dem Kunden ein
            Auftragsformular zur Verfügung gestellt, in das der Kunde seine
            persönlichen Daten, insbesondere Name, Anschrift, E-Mail-Adresse und
            Kontodaten einträgt. Außerdem bestätigt der Kunde, dass er diese AGB
            zur Kenntnis genommen hat und diese AGB akzeptiert. Durch die
            Unterschrift auf dem Auftragsformular gibt der Kunde ein Angebot auf
            Vertragsabschluss ab. Die M3E schickt dem Kunden vorbehaltlich Ziff.
            1.7 unverzüglich nach Zugang des Auftragsformulars eine Bestätigung
            zu und nimmt das Angebot damit an.
          </Typography>
          <Typography variant="body1" gutterBottom>
            b) Für den elektronischen Vertragsschluss wird dem Kunden ein
            Online-Formular unter
            <Link
              href="www.thgquoten.com/register"
              external
              className={classes.link}
            >
              www.thgquoten.com/register
            </Link>{" "}
            zur Verfügung gestellt. Über das Online- Formular gibt der Kunde
            seine persönlichen Daten, insbesondere Name, Anschrift,
            E-Mail-Adresse und Kontodaten ein. Das Online-Formular kann nur
            abgesendet werden, wenn der Kunde diese AGB durch Anklicken einer
            Schaltfläche zur Kenntnis genommen hat und diese AGB akzeptiert.
            Durch Absenden des Online-Formulars gibt der Kunde ein Angebot auf
            Abschluss eines Vertrages ab. Die M3E schickt dem Kunden
            vorbehaltlich Ziff. 1.7 unverzüglich eine Bestätigungs-E-Mail und
            nimmt das Angebot damit an.
          </Typography>

          <Typography variant="body1" gutterBottom>
            1.2 Der Vertrag zwischen M3E und dem Kunden nach Ziff. 1.1. kann
            auch durch einen von der M3E bestimmten Vermittler (z.B. Autohaus)
            zustande kommen. Hierzu stellt der Vermittler dem Kunden entweder
            ein physisches Auftragsformular (Ziff. 1.1.(a)) oder das
            Online-Formular der M3E (Ziff. 1.1.(b)) zur Verfügung, das über eine
            technische Schnittstelle auf der Website des Vermittlers eingebunden
            wird. Ziff. 1.1.(a) bzw. Ziff. 1.1.(b) gelten entsprechend. Der
            Vermittler übermittelt das Angebot des Kunden als Bote an die M3E.
            Die M3E nimmt das Angebot gegenüber dem Kunden an. Der Vertrag kommt
            zwischen M3E und dem Kunden zustande. Das Verhältnis des Vermittlers
            zu M3E ist nicht Gegenstand dieser AGB.
          </Typography>
          <Typography variant="body1" gutterBottom>
            1.3. Zum Vertragsschluss berechtigt sind folgende Kundengruppen:
          </Typography>
          <Typography variant="body1" gutterBottom>
            a) Natürliche Personen (nachfolgend: „Privatkunden“), die das 18.
            Lebensjahr vollendet und ihren Wohnsitz in einem Mitgliedsstaat der
            EU haben.
          </Typography>
          <Typography variant="body1" gutterBottom>
            b) Juristische Personen sowie rechtsfähige Personengesellschaften
            (nachfolgend: „Firmenkunden“) mit Sitz in einem Mitgliedsstaat der
            EU. Bei Abschluss des Vertrages versichert die für den Firmenkunden
            handelnde natürliche Person, über ausreichende Vertretungsmacht zu
            verfügen, um den Vertrag für den Firmenkunden abzuschließen.
          </Typography>
          <Typography variant="body1" gutterBottom>
            1.4. Der Vertrag kann auch durch einen bevollmächtigten
            Stellvertreter des Kunden abgeschlossen werden. Der Stellvertreter
            des Kunden bestätigt im Rahmen des Vertragsschlusses, dass er mit
            Vertretungsmacht des Kunden handelt. Der Vertrag kommt zwischen dem
            Kunden und M3E zustande.
          </Typography>
          <Typography variant="body1" gutterBottom>
            1.5. Kommt ein Vertrag mit einem Firmenkunden zustande, werden
            Geschäfts- oder Einkaufsbedingungen des Firmenkunden nicht
            Vertragsinhalt, auch wenn M3E diesen Bedingungen nicht ausdrücklich
            widerspricht.
          </Typography>
          <Typography variant="body1" gutterBottom>
            1.6. Der Kunde bestimmt M3E als Dritten i.S.v. § 7 Abs. 5 S. 1 38.
            BImSchV. Diese Bestimmung wird wirksam, sobald M3E die THG-Quote dem
            Umweltbundesamt nach Ziff. 6 angemeldet hat.
          </Typography>
          <Typography variant="body1" gutterBottom>
            1.7. Der Kunde hat keinen Anspruch auf Abschluss eines Vertrages mit
            M3E. M3E ist insbesondere berechtigt, ein Angebot des Kunden ohne
            Angaben von Gründen abzulehnen oder nicht anzunehmen.
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b> 2. Benutzerkonto</b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            2.1. Mit Vertragsschluss wird auf der Website der M3E ein
            Benutzerkonto für den Kunden erstellt.
          </Typography>
          <Typography variant="body1" gutterBottom>
            a) Kommt der Vertrag schriftlich zustande (Ziff. 1.1.(a)), wird der
            Kunde mit der Vertragsbestätigung aufgefordert, einen Benutzernamen
            und ein Passwort zu wählen.
          </Typography>
          <Typography variant="body1" gutterBottom>
            b) Kommt der Vertrag elektronisch zustande (Ziff. 1.1.(b)), wählt
            der Kunde bereits mit Ausfüllen des Online-Formulars einen
            Benutzernamen und ein Passwort.
          </Typography>
          <Typography variant="body1" gutterBottom>
            2.2. Der Kunde kann sich unter www.thgquoten.com/login in sein
            Benutzerkonto einloggen. In seinem Benutzerkonto erhält der Kunde
            eine Übersicht über seine angemeldeten Elektrofahrzeuge (Ziff. 3.)
            und seine persönlichen Daten.
          </Typography>
          <Typography variant="body1" gutterBottom>
            2.3. Der Kunde kann über das Benutzerkonto Elektrofahrzeuge anmelden
            (Ziff. 3.) und bereits angemeldete Fahrzeuge verlängern oder
            abmelden (Ziff. 5.).
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b> 3. Anmeldung Elektrofahrzeuge; Abtretung THG-Quote</b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            3.1. Nach Abschluss des Vertrages kann der Kunde beliebig viele
            Elektrofahrzeuge bei der M3E für den THG-Service anmelden. Durch die
            Anmeldung überträgt der Kunde das Vermarktungsrecht der THG-Quote
            für das jeweils angemeldete Elektrofahrzeug andie M3E („Abtretung
            der THG-Quote“). Die Abtretung bezieht sich jeweils auf den inZiff.
            4.1. geregelten Abtretungszeitraum. Eine Anmeldung wird wirksam,
            sobald derKunde für das jeweils angemeldete Elektrofahrzeug einen
            Scan (Dateiformat PDF) der Vorderseite der Zulassungsbescheinigung
            Teil I gemäß § 11 Absatz 1 Satz 1 derFahrzeug-Zulassungsverordnung
            vom 3. Februar 2011 (BGBl. I S. 139) in der jeweilsgültigen Fassung
            (nachfolgend „Fahrzeugschein“) der M3E vorlegt und M3E diese
            Anmeldung nach Ziff. 3.8 bestätigt.
          </Typography>
          <Typography variant="body1" gutterBottom>
            3.2. Für die Durchführung von Anmeldungen stehen dem Kunden zwei
            Möglichkeiten zur Verfügung:{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            a) <b>Digitale Anmeldung:</b> Die Anmeldung erfolgt über ein
            Online-Formular, das über das Benutzerkonto des Kunden (Ziff. 2.)
            aufrufbar ist. Über das Online-Formular wird auch eine Abschrift
            (ein Scann) des Fahrzeugscheins hochgeladen. Der Kunde hat dafür
            Sorge zu tragen, dass die hochgeladene Abschrift des Fahrzeugschein
            (Vorderseite) mit einem neutralen Hintergrund ausgestattet,
            vollständig, gut lesbar und gerade ausgerichtet ist. Sofern der
            Fahrzeugschein bereits vor der Anmeldung des Elektrofahrzeugs
            hochgeladen wurde, wird die Anmeldung abgeschlossen, indem der Kunde
            bestätigt, dass der bereits hochgeladene Fahrzeugschein aktuell ist.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            b){" "}
            <b>
              Analoge Anmeldung: Der Kunde gibt die Anmeldung schriftlich
              gegenüber der M3E ab und übermittelt eine Kopie oder einen Scan
              des Fahrzeugscheins an die M3E.
            </b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Der Kunde hat dafür Sorge zu tragen, dass der Scan des
            Fahrzeugscheins (Vorderseite) mit einem neutralen Hintergrund
            ausgestattet, vollständig, gut lesbar und gerade ausgerichtet ist.
          </Typography>
          <Typography variant="body1" gutterBottom>
            3.3. Sofern der Vertrag unter Einschaltung eines Vermittlers
            geschlossen wurde (Ziff. 1.2.), kann die Anmeldung eines
            Elektrofahrzeugs auch gegenüber dem Vermittler erfolgen. Der
            Vermittler leitet die Anmeldung an die M3E weiter.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            3.4. Anmeldungen können durch einen bevollmächtigten Stellvertreter
            des Kunden durchgeführt werden, der die Abtretungserklärung im Namen
            des Kunden abgibt. Der Stellvertreter des Kunden bestätigt im Rahmen
            der Anmeldung, über eine ausreichende Vertretungsmacht für den
            Kunden zu verfügen.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            3.5. Elektrofahrzeuge können nur angemeldet werden, sofern alle
            folgenden Voraussetzungen erfüllt sind:{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            a) das Elektrofahrzeug ist im Fahrzeugschein bei der Kraftstoffart
            bzw. Energiequelle als „reines Elektrofahrzeug“ (Code: 0004)
            ausgewiesen;
          </Typography>
          <Typography variant="body1" gutterBottom>
            b) der Kunde ist auf dem Fahrzeugschein als Halter des
            Elektrofahrzeugs eingetragen;
          </Typography>
          <Typography variant="body1" gutterBottom>
            c) der Kunde ist Betreiber (§ 2 Nr. 12 Ladesäulenverordnung vom 9.
            März 2016 (BGBl. I S. 457), die durch Artikel 1 der Verordnung vom
            1. Juni 2017 (BGBl. I S. 1520 geändert worden ist) eines nicht
            öffentlichen Ladepunktes.
          </Typography>
          <Typography variant="body1" gutterBottom>
            3.6. Während der Laufzeit des Vertrages kann der Kunde beliebig
            viele Elektrofahrzeuge anmelden. Die Anmeldung kann zeitgleich mit
            dem Vertragsschluss oder danach erfolgen.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            3.7. Mit der Anmeldung des Elektrofahrzeugs erklärt der Kunde sein
            Einverständnis, dass M3E die abgetretene THQ-Quote beim
            Umweltbundesamt anmeldet und zu diesem Zweck eine Kopie des
            Fahrzeugscheins zusammen mit den Daten des Kunden gegenüber dem
            Umweltbundesamt vorlegt.
          </Typography>
          <Typography variant="body1" gutterBottom>
            3.8. M3E bestätigt die Anmeldung gegenüber dem Kunden. M3E ist
            berechtigt, die Anmeldung innerhalb von 4 Wochen abzulehnen. Im Fall
            der Ablehnung der Anmeldung erfolgt keine Vermarktung der THG-Quote
            durch M3E und der Kunde ist in der Verwertung der angemeldeten
            THG-Quote frei.
          </Typography>
          <Typography variant="body1" gutterBottom>
            3.9. M3E ist berechtigt vom Kunden weitere Nachweise zu fordern,
            sofern diese zur Vermarktung der THG-Quote erforderlich sind.
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b> 4. Abtretungszeitraum; Exklusivität</b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            4.1. Der Abtretungszeitraum entspricht dem im Anmeldeprozess
            ausgewählten Kalenderjahr.
          </Typography>

          <Typography variant="body1" gutterBottom>
            4.2. Der Kunde hat sicherzustellen, dass die THG-Quote für den
            Abtretungszeitraum noch nicht durch den Kunden oder einen Dritten
            gegenüber dem Umweltbundesamt gemeldet worden ist.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            4.3. Der Kunde ist verpflichtet, die THG-Quote eines angemeldeten
            Elektrofahrzeugs im Abtretungszeitraum weder selbst an
            Quotenverpflichtete zu vermarkten noch das Recht zur Vermarktung der
            THG-Quote an einen anderen Dritten abzutreten.{" "}
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b> 5. Verlängerung der Anmeldung; Abmeldung; Neuanmeldung</b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            5.1. Der Kunde kann die Anmeldung des Elektrofahrzeugs nach Ablauf
            des Abtretungszeitraums (Ziff. 4.1) verlängern und damit für das
            nachfolgende Kalenderjahr die THG-Quote an die M3E abtreten. Die M3E
            wird den Kunden rechtzeitig informieren, sobald eine Verlängerung
            möglich ist sowie zu den Bedingungen einer Verlängerung.
          </Typography>
          <Typography variant="body1" gutterBottom>
            5.2. Eine Verlängerung der Anmeldung ist nur möglich, sofern die in
            Ziff. 3.5. geregelten Voraussetzungen weiterhin erfüllt sind. Die
            Verlängerung einer Anmeldung kann in den in Ziff. 3.2. genannten
            Wegen durchgeführt werden. Die Verlängerung der Anmeldung erfordert,
            dass der Kunde
          </Typography>
          <Typography variant="body1" gutterBottom>
            a) der M3E erneut einen Scan der Vorderseite des Fahrzeugscheins zur
            Verfügung stellt oder
          </Typography>
          <Typography variant="body1" gutterBottom>
            b) bestätigt, dass der bereits zur Verfügung gestellte
            Fahrzeugschein nach wie vor aktuell ist.
          </Typography>
          <Typography variant="body1" gutterBottom>
            M3E ist zur Verlängerung nicht verpflichtet.
          </Typography>
          <Typography variant="body1" gutterBottom>
            5.3. Sofern der Kunde die Anmeldung des Elektrofahrzeugs nicht
            verlängert, wird das Elektrofahrzeug automatisch mit Ablauf des
            jeweiligen Abtretungszeitraums abgemeldet.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            5.4. Der Kunde ist berechtigt, ein abgemeldetes Elektrofahrzeug nach
            Maßgabe der Ziff. 3. erneut anzumelden.
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b>
              6. Vermarktung der THG-Quote; Anmeldung beim Umweltbundesamt;
              Bearbeitungsentgelt
            </b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            6.1. M3E wird die THG-Quote unter Einhaltung der gesetzlichen Frist
            (§ 8 Abs. 1 38. BImSchV) beim Umweltbundesamt anmelden. Eine Haftung
            scheidet insbesondere auch in solchen Fällen aus, in denen das
            Umweltbundesamt eine Bescheinigung unter Verweis auf eine
            unzureichende Lesbarkeit der übersandten Dokumente (Fahrzeugschein)
            ablehnt.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            6.2. M3E ist berechtigt, die vom Kunden an M3E abgetretene THG-Quote
            ohne vorherige weitere Abstimmung in eigenem Ermessen, im eigenen
            Namen und auf eigene Rechnung an Dritte zu vermarkten.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            6.3. M3E haftet nicht dafür, dass das Umweltbundesamt die THG-Quote
            bescheinigt (§ 8 Abs. 2 38. BImSchV).{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            6.4. Sofern das Umweltbundeamt die THG-Quote nicht bescheinigt (§ 8
            Abs. 2 38. BImSchV), und der Grund für die Nichtbescheinigung nicht
            aus der Sphäre der M3E stammt, erhebt M3E vom Kunden ein
            Bearbeitungsentgelt für die gescheiterte Anmeldung. Die Höhe des
            Bearbeitungsentgelts wird dem Kunden bei der Anmeldung (Ziff. 3.)
            und der Verlängerung der Anmeldung (Ziff. 5.) angezeigt. Sofern die
            Existenz der THG-Quote durch das Umweltbundesamt bescheinigt wird,
            wird kein Bearbeitungsentgelt erhoben.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            6.5. Eine Statusabfrage gegenüber dem Umweltbundesamt zum
            Bearbeitungsstand des Antrags kann ausschließlich durch den Kunden
            selbst und nicht durch M3E vorgenommen werden.
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b> 7. Pflichten des Kunden</b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            7.1. Der Kunde verpflichtet sich, bei der Anmeldung eines
            Elektrofahrzeugs (Ziff. 3.) und bei der Verlängerung der Anmeldung
            (Ziff. 5.) für die Richtigkeit, Vollständigkeit und Aktualität
            seiner Angaben und Nachweise zu sorgen. Für Schäden, die M3E durch
            bewusst oder fahrlässig gemachte unrichtige Angaben oder Nachweise
            des Kunden entstehen, haftet der Kunde.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            7.2. Kunden sind verpflichtet, M3E etwaige Änderungen der Daten
            (insbesondere der Kontodaten) unverzüglich mitzuteilen.{" "}
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b> 8. Vergütung; Abrechnung</b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            8.1. Nach Bescheinigung der THG-Quote eines Elektrofahrzeugs durch
            das Umweltbundesamt (§ 8 Abs. 2 38. BimSchV) erlangt der Kunde
            Anspruch auf eine pauschale Vergütung pro Kalenderjahr und
            angemeldetem Elektrofahrzeug. M3E wird den Kunden über die
            erfolgreiche Bescheinigung durch das Umweltbundesamt informieren.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            8.2. Die Höhe der dem Kunden jeweils zustehenden Vergütung wird bei
            der Anmeldung des Elektrofahrzeugs (Ziff. 3.) bzw. bei der
            Verlängerung der Anmeldung (Ziff. 5.) ermittelt und dem Kunden im
            Rahmen der Anmeldung bzw. der Verlängerung der Anmeldung mitgeteilt.
          </Typography>
          <Typography variant="body1" gutterBottom>
            8.3. An Privatkunden zahlt M3E die Vergütung für die angemeldeten
            Elektrofahrzeuge jeweils innerhalb von vier Wochen nach
            erfolgreicher Bescheinigung der THG-Quote durch das Umweltbundesamt
            aus. Der Privatkunde erhält eine Gutschrift über den
            Auszahlungsbetrag.
          </Typography>
          <Typography variant="body1" gutterBottom>
            8.4. Kunden haben die Möglichkeit, an der Gutschein-Aktion
            (nachfolgend „Aktion“) teilzunehmen. Für diese Aktion gelten
            ergänzend zu den Ziff. 8.1 bis 8.3 folgende Bedingungen:{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            a) Im Rahmen der Aktion haben Kunden die Möglichkeit, anstelle der
            Auszahlung der Vergütung nach Ziff. 8.1 für das Kalenderjahr 2024
            den unter
            <Link
              href="www.gutscheine-thgquoten.de"
              external
              className={classes.link}
            >
              www.gutscheine-thgquoten.de
            </Link>{" "}
            näher beschriebenen Gutschein zu erhalten (nachfolgend
            <b>Gutschein</b>“).
          </Typography>
          <Typography variant="body1" gutterBottom>
            b) Kunden wandeln so Ihren Anspruch auf Auszahlung Ihrer Vergütung,
            in einen Gutschein um. Die Aktion gilt solange der Vorrat reicht.
            Ein Vertrag zu den Bedingungen der Aktion kommt erst durch Annahme
            durch M3E gemäß Ziff. 1.1 zustande.
          </Typography>
          <Typography variant="body1" gutterBottom>
            c) Ein Anspruch auf einen Gutschein entsteht erst, wenn der Kunde
            die M3E für das Verpflichtungsjahr 2024 als Dritten i.S.v. § 7 Abs.
            5 S. 1 38. BImSchV bestimmt hat und das Umweltbundesamt gegenüber
            der M3E die THG-Quote für dieses Verpflichtungsjahr bescheinigt hat.
          </Typography>
          <Typography variant="body1" gutterBottom>
            d) Ein Kunde erhält nach positivem Bescheid für das
            Verpflichtungsjahr wie in Bst (c) beschreiben innerhalb von vier
            Wochen eine Benachrichtigung an die im Registrierungsbereich
            angegebene Email-Adresse versendet, sich einen Gutschein aus der
            Aktion auszusuchen.
          </Typography>
          <Typography variant="body1" gutterBottom>
            8.5. Abrechnungen gegenüber Firmenkunden erfolgen im
            Gutschriftenverfahren. Firmenkunden erhalten jeweils am Ende des
            Monats eine Gutschrift. Die Gutschrift umfasst alle angemeldeten
            Elektrofahrzeuge, für die die THG-Quote durch das Umweltbundesamt im
            Vormonat erfolgreich bescheinigt worden ist. Die Auszahlung erfolgt
            als Gesamtbetrag.
          </Typography>
          <Typography variant="body1" gutterBottom>
            8.6. Der Kunde hat die Möglichkeit, die Gutschrift zu prüfen und
            innerhalb von 30 Tagen nach Erhalt der Gutschrift in Textform
            Widerspruch einzulegen. M3E verpflichtet sich, den eingegangen
            Widerspruch dem Kunden zu bestätigen. Ist M3E nach Ablauf der 30
            Tage kein Widerspruch zugegangen, so können Einwendungen gegen die
            Gutschrift nicht mehr geltend gemacht werden. Der Rechtsweg ist
            insoweit ausgeschlossen. M3E ist verpflichtet, den Kunden in jeder
            Gutschrift auf diese Rechtsfolge hinzuweisen.
          </Typography>
          <Typography variant="body1" gutterBottom>
            8.7. Mit Firmenkunden kann eine gesonderte Vereinbarung zur
            Vergütung und den Zahlungsbestimmungen getroffen werden, die von
            diesen AGB abweicht.
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b> 9. Laufzeit; Kündigung</b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            9.1. Der Vertrag wird auf unbestimmte Zeit geschlossen.
          </Typography>
          <Typography variant="body1" gutterBottom>
            9.2. Der Kunde kann den Vertrag jederzeit ohne Angabe von Gründen in
            Textform ordentlich kündigen. Die Kündigung wird erst wirksam,
            sobald der Abtretungszeitraum (Ziff. 4.1.) aller angemeldeten
            Elektrofahrzeuge des Kunden abgelaufen ist. Eine im Zeitpunkt der
            Kündigung bereits nach Ziff. 3 abgetretene THG-Quote wird nach Ziff.
            6 und 8 vermarktet und vergütet.
          </Typography>
          <Typography variant="body1" gutterBottom>
            9.3. M3E hat das Recht, den Vertrag mit einer Frist von vier Wochen
            zum Monatsende ordentlich zu kündigen. Eine im Zeitpunkt der
            Kündigung bereits beim Umweltbundesamt nach Ziff. 6.1 angemeldete
            THG-Quote wird nach Ziff. 6 und 8 vermarktet und vergütet. Im
            Übrigen erfolgt bei einer Kündigung nach Satz 1 keine Vermarktung
            der nach Ziff. 3 abgetretenen THG-Quote durch M3E und der Kunde ist
            in der Verwertung der angemeldeten THG-Quote frei.
          </Typography>
          <Typography variant="body1" gutterBottom>
            9.4. Im Übrigen ist die ordentliche Kündigung ausgeschlossen. Das
            Recht zur außerordentlichen Kündigung aus wichtigem Grund nach den
            gesetzlichen Vorgaben bleibt unberührt.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            9.5. Die Kündigung bedarf in jedem Fall der Schriftform.
          </Typography>
          <Typography variant="body1" gutterBottom>
            9.6. M3E ist berechtigt, mit Wirkung der Kündigung des Vertrages
            sämtliche Daten, die der Kunde an M3E übermittelt hat zu löschen.
            M3E ist hierzu verpflichtet, sofern diese Daten nicht weiterhin für
            Abrechnungs- oder Nachweiszwecke gespeichert werden müssen. Es
            besteht insbesondere eine dreijährige Aufbewahrungspflicht für den
            Fahrzeugschein des Kunden nach § 7 Abs. 2 S. 4 38. BImSchV.
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b>10. White-Label-Lösung</b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Sofern der Kunde den THG-Service von einem Kooperationspartner als
            White-Label-Lösung in Anspruch nimmt, gelten folgende von den
            vorstehenden Regeln abweichende Vereinbarungen:{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            a) Abweichend von Ziff. 1.1. und Ziff. 1.2. gibt der Kunde das
            Angebot auf Abschluss des Vertrages gegenüber dem
            Kooperationspartner ab. Der Kooperationspartner stellt dazu eine
            eigene Kommunikationsinfrastruktur (Website, Formulare, etc.) zur
            Verfügung. Der Kooperationspartner leitet das Angebot des Kunden als
            Bote an die M3E weiter. M3E nimmt das Angebot des Kunden gegenüber
            dem Kooperationspartner an. Der Kooperationspartner übermittelt dem
            Kunden die Annahmeerklärung der M3E sowie eine Vertragsbestätigung.
            Ziff. 1.3. bis Ziff. 1.7. gelten entsprechend.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            b) Der Kunde erhält abweichend von Ziff. 2. kein Benutzerkonto bei
            M3E. Ziff. 2. ist nicht anwendbar.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            c) Der Kunde tritt die THG-Quote nur für das Kalenderjahr der
            Anmeldung an die M3E ab.
          </Typography>
          <Typography variant="body1" gutterBottom>
            d) Die Anmeldung von Elektrofahrzeugen erfolgt abweichend von Ziff.
            3. gegenüber dem Kooperationspartner. Der Kooperationspartner leitet
            die Anmeldung als Bote an M3E weiter. Ziff. 3. gilt entsprechend.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            e) Die Verlängerung der Anmeldung erfolgt abweichen von Ziff. 5.1.
            gegenüber dem Kooperationspartner. Die Neuanmeldung erfolgt
            abweichend von Ziff. 5.1. gegenüber dem Kooperationspartner. Der
            Kooperationspartner leitet Verlängerungen der Anmeldung sowie
            Neuanmeldungen als Bote an M3E weiter. Ziff. 5. gilt entsprechend.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            f) Der Kunde erhält abweichend von Ziff. 8. <b>keine Vergütung</b>{" "}
            von M3E. Ziff. 8. ist nicht anwendbar.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            g) Zusätzlich zu Ziff. 9. ist M3E berechtigt, den Vertrag zu
            kündigen, sofern der Vertrag zwischen dem Kunden und dem
            Kooperationspartner oder der Vertrag zwischen M3E und dem
            Kooperationspartner beendet wird. M3E ist in diesem Seite 9 von 15
            Fall nicht verpflichtet, im Zeitpunkt der Kündigung bereits an die
            M3E abgetretene THG-Quote an den Kunden zurückzuübertragen. M3E ist
            insbesondere berechtigt, die im Zeitpunkt der Kündigung bereits
            abgetretene THG-Quote zu vermarkten. Ziff. 9.1. bis Ziff. 9.5.
            gelten entsprechend.
          </Typography>
          <Typography color="primary" variant="h6" gutterBottom>
            <b>B. Regelungen für Erbringung Antragsservice</b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Die nachfolgenden Bestimmungen unter Teil B. gelten ausschließlich
            für den Antragsservice und betreffen den Vertragsschluss zwischen
            den Parteien, die Stellung des Förderantrags durch M3E sowie einen
            etwaigen Vergütungsanspruch der M3E gegen den Kunden.{" "}
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b>11. Vertragsschluss</b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            11.1. Der Vertrag zwischen M3E und dem Kunden wird entweder
            schriftlich (a) oder elektronisch (b) abgeschlossen. In beiden
            Fällen werden diese AGB Vertragsgegenstand.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            a) Für den schriftlichen Vertragsschluss wird dem Kunden ein
            Auftragsformular zur Verfügung gestellt. In das Auftragsformular
            trägt der Kunde seine persönlichen Daten, insbesondere Name,
            Anschrift, E-Mail-Adresse und Kontodaten ein und wählt die
            gewünschte Förderung. Außerdem bestätigt der Kunde, dass er diese
            AGB zur Kenntnis genommen hat und diese AGB akzeptiert. Durch die
            Unterschrift auf dem Auftragsformular gibt der Kunde ein Angebot auf
            Abschluss eines Vertrages ab. Die M3E schickt dem Kunden
            unverzüglich nach Zugang des Auftragsformulars eine Bestätigung zu
            und nimmt das Angebot damit an.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            b) Für den elektronischen Vertragsschluss wird dem Kunden ein
            Online-Formular unter{" "}
            <Link
              href="www.m3e-gmbh.com/antragsservice"
              external
              className={classes.link}
            >
              www.m3e-gmbh.com/antragsservice
            </Link>{" "}
            zur Verfügung gestellt. Über das Online-Formular gibt der Kunde
            seine persönlichen Daten, insbesondere Name, Anschrift,
            E-Mail-Adresse und Kontodaten ein und wählt die gewünschte
            Förderung. Das Online-Formular kann nur abgesendet werden, wenn der
            Kunde diese AGB durch Anklicken einer Schaltfläche zur Kenntnis
            genommen hat und diese AGB akzeptiert. Durch Absenden des
            Online-Formulars gibt der Kunde ein Angebot auf Abschluss eines
            Vertrages ab. Die M3E schickt dem Kunden unverzüglich nach Zugang
            des Online-Formulars eine Bestätigungs-E-Mail und nimmt das Angebot
            damit an.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            11.2. Der Vertrag zwischen M3E und dem Kunden nach Ziff. 11.1. kann
            durch die Vermittlung eines Dritten (z.B. Autohaus) zustande kommen.
            Hierzu kann der Dritte dem Kunden das Auftragsformular übergeben
            (schriftlicher Vertragsschluss) oder das Online-Formular (vgl. Ziff.
            1.1.(b)) über eine technische Schnittstelle auf seiner Website
            einbinden (elektronischer Vertragsschluss). Der Dritte übermittelt
            das Angebot des Kunden als Bote an die M3E. Die M3E nimmt das
            Angebot gegenüber dem Kunden an. Der Vertrag kommt zwischen M3E und
            dem Kunden zustande. Das Verhältnis des Dritten zu M3E ist nicht
            Gegenstand dieser AGB.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            11.3. Zum Vertragsschluss berechtigt sind folgende Kundengruppen:{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            a) Natürliche Personen (nachfolgend: „<b>Privatkunden</b>“), die das
            18. Lebensjahr vollendet und ihren Wohnsitz in einem Mitgliedsstaat
            der EU haben.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            b) Juristische Personen sowie rechtsfähige Personengesellschaften
            (nachfolgend: „<b>Firmenkunden</b>“) mit Sitz in einem
            Mitgliedsstaat der EU. Die für den Firmenkunden handelnde natürliche
            Person versichert, bei Abschluss des Vertrages berechtigt zu sein,
            den Firmenkunden rechtsgeschäftlich zu vertreten.
          </Typography>
          <Typography variant="body1" gutterBottom>
            11.4. Der Vertrag kann auch durch einen bevollmächtigten
            Stellvertreter des Kunden abgeschlossen werden. Der Stellvertreter
            des Kunden bestätigt im Rahmen des Vertragsschlusses, dass er mit
            Vertretungsmacht des Kunden handelt. Der Vertrag kommt zwischen dem
            Kunden und M3E zustande.
          </Typography>
          <Typography variant="body1" gutterBottom>
            11.5. Kommt ein Vertrag mit einem Firmenkunden zustande, werden
            Geschäfts- oder Einkaufsbedingungen des Firmenkunden nicht
            Vertragsinhalt, auch wenn M3E diesen Bedingungen nicht ausdrücklich
            widerspricht.
          </Typography>
          <Typography variant="body1" gutterBottom>
            11.6. Der Kunde hat keinen Anspruch auf Abschluss eines Vertrages
            mit der M3E. Die M3E ist insbesondere berechtigt, ein Angebot des
            Kunden ohne Angaben von Gründen abzulehnen oder nicht anzunehmen.
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b>12. Antragstellung durch die M3E; Bestätigung Antragstellung</b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            12.1. Durch den Abschluss des Vertrages beauftragt der Kunde die M3E
            mit der Stellung eines Förderantrags für die gewählte Förderung. M3E
            wird nur als Dienstleister für den Kunden tätig und schuldet keinen
            Erfolg.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            12.2. M3E ist verpflichtet, schnellstmöglich, spätestens innerhalb
            von zwei Wochen nach dem Vertragsschluss (Ziff. 11.1.), den
            beauftragten Förderantrag beim zuständigen Fördergeber im Namen des
            Kunden zu stellen. Die M3E schickt dem Kunden nach erfolgter
            Antragstellung eine Bestätigung der Antragstellung mit dem Datum der
            Antragstellung.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            12.3. M3E wird dem Fördergeber insbesondere alle erforderlichen
            Informationen und Unterlagen für die Beantragung der Förderung
            vorlegen. Die M3E wird im Rahmen der Antragstellung die vom Kunden
            übermittelten Angaben übernehmen.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            12.4. M3E ist nicht verpflichtet, zu prüfen, ob die vom Kunden
            übermittelten Angaben korrekt sind und ob der Kunde die
            Förderbestimmungen des Fördergebers erfüllt.
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b>
              13. Pflichten des Kunden; Kündigungsrecht M3E; Bearbeitungsentgelt
            </b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            13.1. Der Kunde ist verpflichtet, zu prüfen, ob er die
            Förderbedingungen des Fördergebers für die beantragte Förderung
            einhält.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            13.2. Der Kunde ist verpflichtet, der M3E die erforderlichen
            Informationen und Dokumente für die Antragstellung der gewählten
            Förderung zu übermitteln. Der Kunde ist verpflichtet, alle
            Informationen wahrheitsgetreu anzugeben. Die M3E wird den Kunden
            über die erforderlichen Informationen und Dokumente für die gewählte
            Förderung informieren.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            13.3. Sofern der Kunde nach dreimaliger Aufforderung unter Setzung
            einer angemessenen Frist durch M3E die erforderlichen Informationen
            und Dokumente nicht übermittelt, ist die M3E berechtigt,{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            a) ein Bearbeitungsentgelt vom Kunden zu erheben. Die Höhe des
            Bearbeitungsentgelts wird dem Kunden bei Vertragsschluss mitgeteilt
            und kann auf der Website der M3E eingesehen werden.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            b) und/oder den Vertrag ohne Einhaltung einer Frist zu kündigen.{" "}
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b>
              {" "}
              14. Bearbeitung Förderantrag; Auskunftspflicht M3E; Auszahlung
              Förderung
            </b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            14.1. Die M3E hat keinen Einfluss auf die Bearbeitung des
            Förderantrages durch den Fördergeber.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            14.2. Die M3E ist nicht verpflichtet, den Bearbeitungsstand des
            Förderantrags beim Fördergeber anzufragen.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            14.3. Die M3E hat keinen Einfluss auf die Entscheidung des
            Fördergebers über den Förderantrag.
          </Typography>
          <Typography variant="body1" gutterBottom>
            14.4. Sofern die beantragte Förderung durch den Fördergeber gewährt
            wird, wird die Fördersumme vom Fördergeber direkt an den Kunden
            ausbezahlt.
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b>
              {" "}
              15. Vergütung; erfolgsabhängige Vergütung; Übermittlung Bescheid
            </b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            15.1. Der Kunde schuldet für die Durchführung einer Antragstellung
            die Zahlung einer Vergütung an die M3E. Kommt der Vertrag nach Ziff.
            11.2. durch die Vermittlung eines Dritten zustande, kann der Dritte
            mit dem Kunden vereinbaren, dass der Dritte die Vergütungspflicht
            des Kunden in Form der befreienden Schuldübernahme für den Kunden
            übernimmt. Sofern M3E die Vereinbarung zwischen dem Dritten und dem
            Kunden zur Schuldübernahme genehmigt, ist der Dritte gegenüber M3E
            verpflichtet, die Vergütung nach Maßgabe der Ziff. 14.2. zu
            bezahlen. Mit vollständiger Zahlung wird die Zahlungspflicht des
            Kunden erfüllt.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            15.2. Die Vergütung setzt sich zusammen aus einer Pauschale pro
            Förderantrag (a) und zusätzlich optional einer erfolgsabhängigen
            Vergütung, sofern ein positiver Zuwendungsbescheid ergeht
            (nachfolgend: „<b>erfolgsbezogene Vergütung</b>“) (b).
          </Typography>
          <Typography variant="body1" gutterBottom>
            a) <b>Pauschale:</b> Die Höhe der Pauschale pro Förderantrag ergibt
            sich aus den Angaben auf der Website der M3E und wird dem Kunden im
            Zeitpunkt der Angebotsabgabe (Ziff. 10.1.) mitgeteilt. Die Vergütung
            wird nach erfolgter Antragstellung durch die M3E nach Maßgabe der
            Ziff. 1.1. unabhängig vom Erfolg des Förderantrags fällig.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            b) <b>Erfolgsbezogene Vergütung:</b> Die Förderprogramme, für die
            zusätzlich eine erfolgsbezogene Vergütung geschuldet ist, sind auf
            der Website der M3E aufgelistet. Die Höhe der erfolgsbezogenen
            Vergütung wird als prozentualer Anteil an der Fördersumme berechnet.
            Der prozentuale Anteil unterscheidet sich je nach Förderprogramm und
            ist ebenfalls auf der Website der M3E aufgelistet. Die Höhe der
            Fördersumme ergibt sich aus dem Zuwendungsbescheid. Die
            erfolgsbezogene Vergütung wird mit Erhalt des Zuwendungsbescheids
            fällig.
          </Typography>
          <Typography variant="body1" gutterBottom>
            15.3. Der Kunde ist verpflichtet, die M3E über die Entscheidung des
            Fördergebers bezüglich des durch die M3E gestellten Förderantrages
            zu informieren. Hierzu stellt der Kunde der M3E unverzüglich nach
            Erhalt eine Kopie des Ablehnungsbescheids bzw. des
            Zuwendungsbescheids des Fördergebers zur Verfügung.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            15.4. Die M3E wird den Kunden sechs Monate nach Antragstellung
            (Ziff. 11.1.) unter Setzung einer angemessenen Frist auffordern
          </Typography>
          <Typography variant="body1" gutterBottom>
            a) entweder den Zulassungs- oder Ablehnungsbescheid zu übermitteln
          </Typography>
          <Typography variant="body1" gutterBottom>
            b) oder zu bestätigen, dass der Kunde für die beantragte Förderung
            noch keinen Bescheid vom Fördergeber erhalten hat.
          </Typography>
          <Typography variant="body1" gutterBottom>
            15.5. Bestätigt der Kunde daraufhin, dass er noch keinen Bescheid in
            Bezug auf die beantragte Förderung vom Fördergeber erhalten hat
            (Ziff. 15.4.(b)), wiederholt die M3E die Aufforderung nach Maßgabe
            der Ziff. 15.4.
          </Typography>
          <Typography variant="body1" gutterBottom>
            15.6. Sofern der Kunde für die gewählte Förderung nach Maßgabe der
            Ziff. 15.1. eine Vergütung schuldet und der M3E nach Ablauf der
            Frist (Ziff. 15.4.) weder einen Bescheid übermittelt (Ziff.
            14.4.(a)) noch der M3E bestätigt hat, dass er noch keinen Bescheid
            im Hinblick auf die beantragte Förderung erhalten hat (Ziff.
            14.4.(b)), wird die erfolgsbezogene Vergütung (Ziff. 14.2.(b))
            fällig. Die M3E legt bei der Berechnung der erfolgsbezogenen
            Vergütung in diesem Fall die beantragte Förderhöhe zugrunde. 15.7.
            Sämtliche in diesen AGB geregelten oder sich aus ihm
          </Typography>
          <Typography variant="body1" gutterBottom>
            15.7. Sämtliche in dem Teil B. dieser AGB geregelten oder sich aus
            ihm ergebenden Beträge verstehen sich jeweils zzgl. der gesetzlichen
            Umsatzsteuer in der jeweils gültigen Höhe (derzeit 19 %), sofern
            diese anfällt.
          </Typography>
          <Typography variant="body1" gutterBottom>
            15.8. Mit Firmenkunden kann eine gesonderte Vereinbarung zur
            Vergütung und den Zahlungsbestimmungen getroffen werden, die von
            diesen AGB abweicht.
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b>16. Abrechnung</b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            16.1. Die M3E rechnet die Vergütung (Ziff. 14.1.) und die
            erfolgsabhängige Vergütung (Ziff. 14.2.) getrennt ab.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            16.2. Zahlungen sind innerhalb von 10 Werktagen nach Erhalt der
            Rechnung zur Zahlung fällig.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            16.3. Einwendungen gegen die Abrechnung i.S.v. Ziff. 15.1. sind
            innerhalb von 14 Tagen an die M3E begründet geltend zu machen. Nach
            Ablauf dieser Frist kann der Kunde keine Einwendungen gegen die
            Abrechnung mehr geltend machen, sofern die Abrechnung einen
            entsprechenden Hinweis auf diese Rechtsfolge enthält.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            16.4. Dem Kunden stehen Aufrechnungs- und Zurückbehaltungsrechte
            gegen Ansprüche der M3E nur insoweit zu, als sein Anspruch
            rechtskräftig festgestellt oder unbestritten ist.{" "}
          </Typography>
          <Typography color="primary" variant="h6" gutterBottom>
            <b>C. Allgemeine Bestimmungen</b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Die nachfolgenden Bestimmungen unter Teil C. gelten sowohl für den
            TGH Service als auch für den Antragsservice.
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b> 17. Allgemeine Mitwirkungspflicht</b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Der Kunde ist verpflichtet, die Voraussetzungen dafür zu schaffen,
            dass die vereinbarten Leistungen durch M3E erbracht werden können.
            Der Kunde ist insbesondere verpflichtet, erforderliche Dokumente
            fristgerecht zu übermitteln.
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b> 18. Haftungsbegrenzung</b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            18.1. Unabhängig vom Rechtsgrund, haftet die M3E für Schäden nur in
            den nachfolgenden Grenzen:{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            a) Bei Vorsatz oder grober Fahrlässigkeit der M3E, ihres
            gesetzlichen Vertreters oder sonstigen Erfüllungsgehilfen
            unbegrenzt;{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            b) Bei schuldhafter Verletzung wesentlicher Vertragspflichten durch
            M3E, ihres gesetzlichen Vertreters oder sonstigen Erfüllungsgehilfen
            ohne Vorsatz oder grobe Fahrlässigkeit begrenzt auf die bei
            Vertragsschluss vorhersehbaren und vertragstypischen Schäden.
            Wesentliche Vertragspflichten sind solche, deren Erfüllung den
            Vertrag prägen und auf die die andere Partei vertrauen darf.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            18.2. Darüber hinaus ist eine Haftung der M3E, soweit nicht
            zwingende Rechtsvorschriften entgegenstehen, ausgeschlossen.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            18.3. Die Haftungsbegrenzungen nach den Ziff. 17.1. und 17.2. gelten
            nicht für Schäden an Körper, Leben und Gesundheit.
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b>19. Informationen zur Online-Streitbeilegung (Verbraucher)</b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Im Rahmen der Verordnung über Online-Streitbeilegung zu
            Verbraucherangelegenheiten steht dem Privatkunden unter{" "}
            <Link
              href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
              external
              className={classes.link}
            >
              https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage
            </Link>{" "}
            eine Online-Streitbeilegungsplattform der EU-Kommission zur
            Verfügung.{" "}
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b>
              20. Alternative Streitbeilegung gemäß
              Verbraucherstreitbeilegungsgesetz (Verbraucher)
            </b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            M3E ist weder bereit noch verpflichtet, an einem
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.{" "}
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b>21. Datenschutz</b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            21.1. M3E wird die Daten des Kunden nach den gesetzlichen
            Bestimmungen erheben, verarbeiten und nutzen.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            21.2. Ohne Einwilligung des Kunden wird M3E die Daten des Kunden nur
            erheben, verarbeiten oder nutzen, soweit dies zur Erfüllung des
            Vertragszwecks erforderlich ist.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            21.3. Zu den Einzelheiten über Umfang und Verwendung von Daten und
            weitere Informationen zur Datenerhebung, -verarbeitung und -nutzung
            wird auf die Datenschutzerklärung verwiesen, jederzeit unter{" "}
            <Link
              href="https://m3e-gmbh.com/datenschutz"
              external
              className={classes.link}
            >
              https://m3e-gmbh.com/datenschutz
            </Link>
            über den Reiter „Datenschutzerklärung“ abrufbar ist.
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b>22. Abschließende Vereinbarungen</b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            22.1. Abweichende oder ergänzende Vereinbarungen zwischen den
            Vertragsparteien bestehen nicht und bedürfen, soweit gesetzlich
            zulässig, der Schriftform. Dies gilt auch für die Änderung des
            Schriftformerfordernisses selbst. Änderungen oder Ergänzungen durch
            individuelle Vereinbarung bedürfen nicht der Schriftform.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            22.2. Sollte eine Bestimmung dieser allgemeinen Geschäftsbedingungen
            unwirksam sein, wird die Wirksamkeit der übrigen Bestimmungen
            dadurch nicht berührt. Die Parteien werden versuchen, eine
            unwirksame Bestimmung durch eine andere Bestimmung zu ersetzen, die
            dem wirtschaftlichen Zweck der ursprünglichen Bestimmung möglichst
            nahe kommt, aber wirksam ist. Dasselbe gilt für Lücken des
            Vertrages.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            22.3. Auf diese allgemeinen Geschäftsbedingungen findet deutsches
            Recht Anwendung.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            22.4. Für sämtliche Streitigkeiten aus dem Vertrag wird Berlin als
            Gerichtsstand vereinbart, sofern der Kunde Kaufmann i.S.d. HGB ist
            und kein anderer ausschließlicher Gerichtsstand besteht.{" "}
          </Typography>
          <Typography color="primary" variant="subtitle" gutterBottom>
            <b> 23. Widerrufsbelehrung (Verbraucher)</b>{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            23.1. Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von
            Gründen diesen Vertrag zu widerrufen.
          </Typography>
          <Typography variant="body1" gutterBottom>
            23.2. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des
            Vertragsschlusses.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            23.3. Um Ihr Widerrufsrecht auszuüben, müssen Sie M3E (M3E GmbH,
            Torstraße 23, 10119 Berlin, 030 / 4036 72121,{" "}
            <Link href="thg@m3e-gmbh.com" external className={classes.link}>
              thg@m3e-gmbh.com
            </Link>{" "}
            mittels einer eindeutigen Erklärung (z. B. ein mit der Post
            versandter Brief, oder E-Mail) über Ihren Entschluss, diesen Vertrag
            zu widerrufen, informieren. Sie können dafür das beigefügte
            Muster-Widerrufsformular (Anhang 1) verwenden, das jedoch nicht
            vorgeschrieben ist.{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            23.4. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
            Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
            Widerrufsfrist absenden.{" "}
          </Typography>

          <Box display="flex" justifyContent="flex-end" my={3}>
            <Button
              color="primary"
              variant="outlined"
              component="a"
              href={pdfagb}
              download
            >
              AGB herunterladen
            </Button>
          </Box>
          <Box display="flex" justifyContent="flex-end" my={3}>
            <Button
              color="primary"
              variant="outlined"
              component="a"
              href={pdf}
              download
            >
              Widerrufsformular herunterladen
            </Button>
          </Box>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Agb;
